<template>
    <div>
        <el-menu
                 :mode="menuMode"
                 :default-active="this.$router.path"
                 :router="true"
                 active-text-color="#FF0000"
                 text-color="black"
                 :ellipsis="false">
            <el-menu-item index="0">
                <img
                    style="height: 45px;margin-left: 40%"
                    src="../../assets/logo/topLogo.png"
                    alt="Element logo"
                />
            </el-menu-item>
            <div class="flex-grow" />
            <el-menu-item v-if="!isMobile" index="/home">
<!--                <template v-slot:title>首页</template>-->
              <span class="menu-text">首页</span>
            </el-menu-item>
            <!-- 品牌介绍 -->
            <el-menu-item v-if="!isMobile" index="/about">
<!--                <template v-slot:title>品牌介绍</template>-->
              <span class="menu-text">关于我们</span>

<!--                <el-menu-item index="1-1">企业概况</el-menu-item>-->
<!--                <el-menu-item index="1-2">发展历程</el-menu-item>-->
<!--                <el-menu-item index="1-3">品牌IP</el-menu-item>-->
<!--                <el-menu-item index="1-4">企业文化</el-menu-item>-->
            </el-menu-item>
            <!-- 门店展示 -->
            <el-menu-item v-if="!isMobile" index="/show">
<!--                <template v-slot:title>门店展示</template>-->
              <span class="menu-text">门店展示</span>

<!--                <el-menu-item index="2-1">门店形象</el-menu-item>-->
<!--                <el-menu-item index="2-2">门店全景</el-menu-item>-->
<!--                <el-menu-item index="2-3">门店查询</el-menu-item>-->
            </el-menu-item>
            <!-- 招商加盟 -->
            <el-menu-item v-if="!isMobile" index="/join">
<!--                <template v-slot:title>招商加盟</template>-->
              <span class="menu-text">招商加盟</span>

<!--                <el-menu-item index="3-1">加盟支持</el-menu-item>-->
<!--                <el-menu-item index="3-2">加盟条件</el-menu-item>-->
<!--                <el-menu-item index="3-3">加盟流程</el-menu-item>-->
<!--                <el-menu-item index="3-4">常见问题</el-menu-item>-->
            </el-menu-item>
            <!-- 品牌咨询 -->
            <el-menu-item v-if="!isMobile" index="brand">
<!--                <template v-slot:title>品牌咨询</template>-->
              <span class="menu-text">品牌资讯</span>

<!--                <el-menu-item index="4-1">品牌视频</el-menu-item>-->
<!--                <el-menu-item index="4-2">媒体资讯</el-menu-item>-->
<!--                <el-menu-item index="4-3">品牌动态</el-menu-item>-->
            </el-menu-item>
            <!-- 联系我们 -->
            <el-menu-item v-if="!isMobile" index="contact">
<!--                <template v-slot:title>联系我们</template>-->
              <span class="menu-text">联系我们</span>

<!--                <el-menu-item index="5-1">联系我们</el-menu-item>-->
<!--                <el-menu-item index="5-2">人才招聘</el-menu-item>-->
<!--                <el-menu-item index="5-3">公司地址</el-menu-item>-->
<!--                <el-menu-item index="5-4">投诉建议</el-menu-item>-->
            </el-menu-item>

            <div class="flex-grow1"/>
            <div v-if="!isMobile" class="span-style">
  <!--                <el-icon color="red" size="35px" ><Phone /></el-icon>-->
                  <span style="color: RED;font-size: 20px;">加盟电话：</span>
                  <span style="font-size: 20px;">400-853-8778</span>
              </div>
            <!-- 移动端的菜单项，保留你原先的代码 -->
            <el-sub-menu v-if="isMobile" index="8">
                <template v-slot:title><i class="el-icon-menu"></i></template>
                <el-menu-item index="6-1">企业概览</el-menu-item>
                <el-menu-item index="6-2">发展历程</el-menu-item>
                <el-menu-item index="6-3">公司介绍</el-menu-item>
                <el-menu-item index="6-4">招商加盟</el-menu-item>
                <el-menu-item index="6-5">联系我们</el-menu-item>
            </el-sub-menu>
        </el-menu>
    </div>
</template>


<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
export default {
    name: "HeaderMenu",
    methods:{


  },
    setup() {

        const isMobile = ref(false);

        const checkMobile = () => {
            isMobile.value = window.innerWidth < 768;
        };

        onMounted(() => {
            checkMobile();
            window.addEventListener('resize', checkMobile);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkMobile);
        });

        const menuMode = ref('horizontal');
        if (isMobile.value) {
            menuMode.value = 'vertical';
        }

        return {
            isMobile,
            menuMode
        };
    }
}
</script>

<style scoped>
/* 如果需要其他样式，可以在这里添加 */
.el-menu{
    font-family: 微软雅黑;
}

.flex-grow {
    flex-grow: 0.5;
}
.flex-grow1 {
    flex-grow: 0.4;
}

.el-menu-item {
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item.is-active {
  background-color: #FFFFFF !important;
  font-size: 18px; /* 或您希望的任何尺寸 */
}


/deep/.el-menu .el-menu-item:hover{
  outline: 0 !important;
  color: RED !important;
  background: linear-gradient(270deg, #F2F7FC 0%, #FEFEFE 100%)!important;
}

.menu-text{
  display: inline-block; /* 允许 transform 应用于 inline 元素 */
  transition: transform 0.3s ease-in-out; /* 定义动画效果和持续时间 */
  font-size: 20px;
}

.el-menu-item:not(.is-active) .menu-text:hover{
  transform: scale(1.1); /* 只放大文字 */
}

.span-style {
  height: 20px;
  line-height: 50px;
  font-weight: bold;
}
</style>
